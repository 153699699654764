/* eslint-disable camelcase */

/*
  Make some things from the webpack environment available globally on the window object.

  This is to allow webpack-enabled pages to still have a few bits of inline js:
  - inline click handlers
  - addJs scripts
  - addJsFunc scripts

*/

import conv from '@/conv'
import socket from '@/socket'

import { expose } from '@/utils'

import {
  chat,
  pulseInfo,
  pulseError,
  pulseSuccess,
  profile,
  goTo,
  reload,
  showLoader,
  hideLoader,
  wantToHelpStore,
  withdrawStoreRequest,
} from '@/script'

expose({
  chat,
  pulseInfo,
  pulseError,
  pulseSuccess,
  profile,
  goTo,
  reload,
  showLoader,
  hideLoader,
  wantToHelpStore,
  withdrawStoreRequest,
  conv,
  sock: socket,
})
